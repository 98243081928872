import io from "socket.io-client";

const options = {
  path: "/socket.io",
  transports: ["websocket"],
};

// const socket = io("https://rwminvest-dev.tecman.ru/", options);
const socket = io("https://pre-prod.rwminvest.ru/", options);
//const socket = io("http://185.11.51.164/", options);
export default socket;
