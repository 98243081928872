class UserModel {
    userName = {
        name: "",
        mname: "",
        sname: ""
    };
    registrationMethod = null;
    ki = null;
    phone = null;
    email = null;
    birthday = null;
    birthplace = null;
    actualAddress = null;
    registrationAddress = null;
    postcodeactualAddress = null;
    postcoderegistrationAddress = null;
    inn = null;
    password = null;
    status = 0;
    qualifiedInvestor = false;
    booleanAgreements = {
        isRegNaturalAddress: {
            label: "Совпадает с адресом проживания",
            value: false,
        },
        isElectronicDocumentManagement: {
            label: "Присоединяюсь к <a href='/client/v1/files/get?type=101' target='_blank'>соглашению об электронном документообороте</a> с УК РВМ Капитал",
            value: false
        },
        isPersonalDataProcessing: {
            label: "Даю <a href='/client/v1/files/get?type=100' target='_blank'>согласие на обработку персональных данных</a>",
            value: false
        },
        isResident: {
            label: "Не являюсь налоговым резидентом Соединенных Штатов Америки",
            value: false
        },
        isOfficialThisRelative: {
            label: "Не являюсь публичным должностным лицом, супругом(ой) или близким родственником такого лица",
            value: false
        },
        isOwnPhone: {
            label: "Подтверждаю, что являюсь фактическим пользователем абонентского номера",
            value: false
        }
    };
    passport = {
        number: "",
        department: "",
        issue: "",
        issueDate: "",
        scan: {
            file: [],
            type: "",
        }
    };
    snils = {
        number: "",
        scan: {
            file: [],
            type: "",
        }
    };

    constructor(user) {
        if (user) {
            if (JSON.stringify(user) !== "{}") {
                if (user.userName) {
                    this.loadUser(user)
                } else {
                    this.createUser(user)
                }
            }
        }
    }

    createUser(user) {
        this.userName.name = user.name ?? ""
        this.userName.mname = user.patronymic ?? ""
        this.userName.sname = user.surname ?? ""
        this.phone = this.formatPhone(user.phone) ?? ""
        this.email = user.email ?? ""
        this.ki = user.ki ?? ""
        this.registrationMethod = user.registration_method ?? ""
        this.birthday = user.userInfo?.birthday ?? ""
        this.birthplace = user.userInfo?.birthplace ?? ""
        this.actualAddress = user.userInfo?.address_residence ?? ""
        this.postcodeactualAddress = user.userInfo?.postcode_address_residence ?? ""
        this.registrationAddress = user.userInfo?.address_registration ?? ""
        this.postcoderegistrationAddress = user.userInfo?.postcode_address_registration ?? ""
        this.inn = user.userInfo?.inn ?? ""
        this.password = user.userInfo?.password ?? ""
        this.status = user.status ?? ""
        this.qualifiedInvestor = user.qualified_investor ?? false
        this.booleanAgreements.isRegNaturalAddress.value = user.userInfo?.isRegNaturalAddress ?? false
        this.booleanAgreements.isElectronicDocumentManagement.value = user.userInfo?.isElectronicDocumentManagement ?? false
        this.booleanAgreements.isPersonalDataProcessing.value = user.userInfo?.isPersonalDataProcessing ?? false
        this.booleanAgreements.isResident.value = user.userInfo?.isResident ?? false
        this.booleanAgreements.isOfficialThisRelative.value = user.userInfo?.isOfficialThisRelative ?? false
        this.booleanAgreements.isOwnPhone.value = user.userInfo?.isOwnPhone ?? false
        this.passport.department = user.userInfo?.passport_division_code ?? ""
        this.passport.department = this.passport.department == '-' ? '' : this.passport.department;
        this.passport.number = user.userInfo?.passport_number ?? ""
        this.passport.issue = user.userInfo?.passport_issued_by ?? ""
        this.passport.issueDate = user.userInfo?.passport_date_issue ?? ""
        this.passport.scan = user.userFiles ? this.#finedFiles(user.userFiles, 2) : {file: [], type: ""}
        this.snils.number = user.userInfo?.snils ?? ""
        this.snils.scan = user.userFiles ? this.#finedFiles(user.userFiles, 1) : {file: [], type: ""}
    }

    loadUser(user) {
        this.userName.name = user.userName.name
        this.userName.mname = user.userName.mname
        this.userName.sname = user.userName.sname
        this.phone = this.formatPhone(user.phone)
        this.email = user.email
        this.ki = user.ki
        this.registrationMethod = user.registrationMethod
        this.birthday = user.birthday
        this.birthplace = user.birthplace
        this.actualAddress = user.actualAddress
        this.postcodeactualAddress = user.postcodeactualAddress
        this.registrationAddress = user.registrationAddress
        this.postcoderegistrationAddress = user.postcoderegistrationAddress
        this.inn = user.inn
        this.password = user.password
        this.status = user.status
        this.qualifiedInvestor = user.qualifiedInvestor
        this.booleanAgreements.isRegNaturalAddress.value = user.booleanAgreements ? user.booleanAgreements.isRegNaturalAddress.value : false
        this.booleanAgreements.isElectronicDocumentManagement.value = user.booleanAgreements ? user.booleanAgreements.isElectronicDocumentManagement.value : false
        this.booleanAgreements.isPersonalDataProcessing.value = user.booleanAgreements ? user.booleanAgreements.isPersonalDataProcessing.value : false
        this.booleanAgreements.isResident.value = user.booleanAgreements ? user.booleanAgreements.isResident.value : false
        this.booleanAgreements.isOfficialThisRelative.value = user.booleanAgreements ? user.booleanAgreements.isOfficialThisRelative.value : false
        this.booleanAgreements.isOwnPhone.value = user.booleanAgreements ? user.booleanAgreements.isOwnPhone.value : false
        this.passport.department = user.passport.department
        this.passport.department = this.passport.department == '-' ? '' : this.passport.department;
        this.passport.number = user.passport.number
        this.passport.issue = user.passport.issue
        this.passport.issueDate = user.passport.issueDate
        this.passport.scan = user.passport.scan
        this.snils.number = user.snils.number
        this.snils.scan = user.snils.scan
    }

    setPassport(passport) {
        this.passport.department = passport.department ? passport.department : passport.userInfo?.passport_division_code ? passport.userInfo.passport_division_code : ""
        this.passport.department = this.passport.department == '-' ? '' : this.passport.department;
        this.passport.number = passport.number ? passport.number : passport.userInfo?.passport_number ? passport.userInfo.passport_number : ""
        this.passport.issue = passport.issue ? passport.issue : passport.userInfo?.passport_issued_by ? passport.userInfo.passport_issued_by : ""
        this.passport.issueDate = passport.issueDate ? passport.issueDate : passport.userInfo?.passport_date_issue ? passport.userInfo.passport_date_issue : ""
        this.passport.scan = passport.scan ? passport.scan : this.#finedFiles(passport.userFiles, 2)
    }

    setSnils(snils) {
        this.snils.number = snils.number ? snils.number : ""
        this.snils.scan = snils.scan ? snils.scan : this.#finedFiles(snils.userFiles, 1)
    }

    setProperty({key, value}) {
        this[key] = value
    }

    deleteFiles(type) {
        const param = type === 1 ? "snils" : type === 2 ? "passport" : null
        if (param !== null) {
            this[param].scan.file.length = 0
            this[param].scan.type = 0
        }
    }

    formatPhone(phone) {
        if(phone) {
            let clean = ('' + phone).replace(/\D/g, '')
            let match = clean.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
            return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`
        }
    }

    #finedFiles(userFiles, type) {
        let scan = {
            type: "",
            file: [],
        }
        if (userFiles) {
            let file = userFiles.filter((el) => el.file_type && el.file_type === type)
            if (file && file.length > 0) {
                scan.type = file[0].file_type
                scan.file.push({name: file[0].origin_name, data: file[0].file, id: file[0].id})
            }
        }
        return {...scan}
    }

    clear() {
        this.userName.name = ""
        this.userName.mname =""
        this.userName.sname =""
        this.phone =""
        this.email =""
        this.ki =""
        this.registrationMethod =null
        this.birthday =""
        this.birthplace =""
        this.actualAddress =""
        this.registrationAddress =""
        this.postcodeactualAddress =""
        this.postcoderegistrationAddress =""
        this.inn =""
        this.password =""
        this.status =""
        this.qualifiedInvestor =false
        this.booleanAgreements.isRegNaturalAddress.value =false
        this.booleanAgreements.isElectronicDocumentManagement.value =false
        this.booleanAgreements.isPersonalDataProcessing.value =false
        this.booleanAgreements.isResident.value =false
        this.booleanAgreements.isOfficialThisRelative.value =false
        this.booleanAgreements.isOwnPhone.value = false
        this.passport.department =""
        this.passport.number =""
        this.passport.issue =""
        this.passport.issueDate =""
        this.passport.scan.type =""
        this.passport.scan.file.length = 0
        this.snils.number =""
        this.snils.scan.type =""
        this.snils.scan.file.length = 0
    }
}

export default UserModel